import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import loadable from "@loadable/component"
// import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "../../../templates/style.css"
import next from "../../../assets/images/HeaderMenus/Next.svg"
import back from "../../../assets/images/HeaderMenus/Back.svg"
const Slider = loadable(() => import("react-slick"))

function NextArrow(props) {
  const { style, onClick } = props
  return (
    <div
      style={{ ...style, display: "block" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <button className="slick-next-arrow">
        <img src={next} alt="Next Arrow" width="52" height="25" />
      </button>
    </div>
  )
}

function PrevArrow(props) {
  const { style, onClick } = props
  return (
    <div
      style={{ ...style, display: "block" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <button className="slick-prev-arrow">
        <img src={back} alt="Next Arrow" width="52" height="25" />
      </button>
    </div>
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  focusOnSelect: true,
  arrows: true,
  resumeOnVisible: true,
  useTransform: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
}

const ImageTestimonials = ({ ImageTestimonials: { select, testimonials } }) => {
  const data = useStaticQuery(graphql`
    query ImageTestimonialsQuery {
      allWpImageTestimonial {
        nodes {
          ImagesTestimonialsCPT {
            poster {
              mediaItemUrl
              srcSet
              altText
              mediaDetails {
                width
                height
              }
            }
          }
          title
        }
      }
    }
  `)

  const imageTestimonialsNodes =
    select === "All" ? data?.allWpImageTestimonial?.nodes : testimonials
  return (
    <div>
      <div className="px-6 max-w-screen-2xl mx-auto	 lg:px-12">
        <div className="slider-btn">
          <Slider
            {...settings}
            className="custom-slick-slider slider slider__container mx-auto relative p-0 lg:px-12"
          >
            {imageTestimonialsNodes.map(({ ImagesTestimonialsCPT }) => (
              <div className="p-4" key={Math.random()}>
                <div
                  className="min-w-full relative  transform rounded-md duration-300 ease-in-out cursor-pointer"
                  key={Math.random()}
                  aria-hidden="true"
                >
                  <div className="imgWrapper m-auto max-w-full relative ">
                    <img
                      src={ImagesTestimonialsCPT?.poster?.mediaItemUrl}
                      width={ImagesTestimonialsCPT.poster?.mediaDetails?.width}
                      height={
                        ImagesTestimonialsCPT.poster?.mediaDetails?.height
                      }
                      alt="Image-Poster"
                      title="Image-Poster"
                      className="w-full h-auto object-cover rounded-md"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default ImageTestimonials
